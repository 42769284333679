import React, {
    Fragment,
    useState,
    useEffect,
    useContext,
} from "react";
import {API_URL} from "../../utils/backend.instance";
import Breadcrumb from "../../layout/breadcrumb";
import Error404 from "../../pages/errors/error404";
import Alert from "../../redux/Alert";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Table,
} from "reactstrap";
import AppContext from "../../context/Context";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const BlockDate = () => {
    const notyf = new Notyf();
    const [blockedDates, setBlockedDates] = useState([]);
    const [showDates, setshowDates] = useState([]);
    let accessToken = ""
    if (localStorage.access) {
        accessToken = localStorage.access;
    }else {
        window.location.replace(`/signin/`);
    }
    const [startDate, setStartDate] = useState(new Date());
    const [status, setStatus] = useState(true);

    const {
        config: {group}
    } = useContext(AppContext)

    const onChangeStatus = (e) => {
        setStatus(e.value)
    };

    const getBlockData = async () =>{
        const apiUrl = `${API_URL}/fournisseur/block-date/`;

        try {
            const response = await fetch(`${apiUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            let bl_data = [];
            const data = await response.json();
            setshowDates(data)
            data?.results?.forEach(item => {
                if(item.is_active){
                    bl_data.push(new Date(item.date))
                }
            })
            setBlockedDates(bl_data)
        }catch(err){
            notyf.error("Error ", err)
        }
    }

    useEffect(() => {
        getBlockData();
    }, []);


    const onSubmit = async (e) => {
        e.preventDefault();
        const date = startDate.toISOString().split('T')[0];
    
        const apiUrl = `${API_URL}/fournisseur/block-date/`;
        
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ date : date, is_active: status }),
          });
          if (!response.ok) {
            notyf.error("Data not added")
          }else{
            notyf.success("Data added successfully")
            await getBlockData();
          }
        } catch (error) {
            notyf.error("Error ", error)
        }
      };

    let user_groups_name = group;
    const filterDate = (date) => {
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const isBlocked = blockedDates.some((blockedDate) => {
            const isSameDay =
                blockedDate.getDate() === date.getDate() &&
                blockedDate.getMonth() === date.getMonth() &&
                blockedDate.getFullYear() === date.getFullYear();
            return isSameDay;
        });
        return !isWeekend && !isBlocked;
    };
    return (
        <Fragment>
            <Breadcrumb parent="Block Date"/>
            {(user_groups_name && user_groups_name.indexOf("admin") !== -1) ? (
                <div>
                    <Container fluid={true}>
                        <Alert/>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="6">
                                                <Form onSubmit={(e) => onSubmit(e)}>
                                                    <Label>Select the date</Label>
                                                    <Row>
                                                        <Col>
                                                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" onChange={(e) => { onChangeStatus(e)}}>
                                                                <option value={true}>Active</option>
                                                                <option value={false}>Inactive</option>
                                                            </select>
                                                        </Col>
                                                        <Col>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={(date) => setStartDate(date)}
                                                            filterDate={filterDate}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <button
                                                                type="submit"
                                                                className="btn "
                                                                style={{backgroundColor: "#FDF203"}}
                                                            >
                                                                Ajouter
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <Col sm="12">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {showDates?.results?.map((item, index) => (
                                                            
                                                            <tr key={index}>
                                                                <td>{item.date}</td>
                                                                <td>{item.is_active? "Active" : "Inactive"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <Error404/>
            )}
        </Fragment>
    );
};

export default BlockDate;