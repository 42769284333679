import React, { Fragment, useContext, useEffect, useState } from "react";
import { API_URL } from "../../utils/backend.instance";
import Alert from "../../redux/Alert";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import AppContext from "../../context/Context";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const EditFinanceRequest = () => {
    const notyf = new Notyf();
  let accessToken = "";
  if (localStorage.access) {
    accessToken = localStorage.access;
  } else {
    window.location.replace(`/signin/`);
  }

  const [formData, setFormData] = useState({
    nombre_de_colis: "",
    total_cod: "",
    cous_livraison: "",
    total_hfl: "",
    date: new Date(),
    status: "",
  });
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [blockedDates, setBlockedDates] = useState([]);
  const {
    config: { group },
  } = useContext(AppContext);

  const { id } = useParams();

  const getCurrentNew = async () => {
    setLoading(true);
    const apiUrl = `${API_URL}/fournisseur/finance-request/${id}/`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        notyf.error("Failed to fetch finance request data");
      }
      const data = await response.json();
      setFormData({
        nombre_de_colis: data.nombre_de_colis || "",
        total_cod: data.total_cod || "",
        cous_livraison: data.cous_livraison || "",
        total_hfl: data.total_hfl || "",
        date: data.date || "",
        status: data.status || "",
      });
    } catch (error) {
        notyf.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCurrentNew();
  }, [id]);

    const getBlockData = async () =>{
        const apiUrl = `${API_URL}/fournisseur/block-date/`;

        try {
            const response = await fetch(`${apiUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            let bl_data = [];
            const data = await response.json();
            data?.results?.forEach(item => {
                if(item.is_active){
                    bl_data.push(new Date(item.date))
                }
            })
            setBlockedDates(bl_data)
        }catch(err){
            notyf.error("Error ", err)
        }
    }

    useEffect(() => {
        getBlockData();
    }, []);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = `${API_URL}/fournisseur/finance-request/${id}/`;

    try {
      const response = await fetch(apiUrl, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        notyf.error("Failed to update finance request");
      }
      notyf.success("Finance request updated successfully");
      window.location.replace(`/request-finance/`);
    } catch (error) {
        notyf.error(error.message);
    }
  };

    const filterDate = (date) => {
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;
        const isBlocked = blockedDates.some((blockedDate) => {
            const isSameDay =
            blockedDate.getDate() === date.getDate() &&
            blockedDate.getMonth() === date.getMonth() &&
            blockedDate.getFullYear() === date.getFullYear();

            console.log(`Comparing ${date} with ${blockedDate}: ${isSameDay}`);
            return isSameDay;
        });
        return !isWeekend && !isBlocked;
    };

  return (
    <Fragment>
      <Breadcrumb parent="Finance" title="Modifier la demande de financement" />
      <Container fluid={true}>
        <Form className="theme-form" onSubmit={onSubmit}>
          <Alert />
          <Row>
            <Col sm="12">
              {group.indexOf("admin") !== -1 ? (
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Row>
                            <Col sm="3">
                              <Label><b>Nombre de colis</b></Label>
                              <Input
                                className="form-control digits"
                                name="nombre_de_colis"
                                id="nombre_de_colis"
                                type="text"
                                value={formData.nombre_de_colis}
                                onChange={onChange}
                              />
                            </Col>
                            <Col sm="3">
                              <Label><b>Total COD</b></Label>
                              <Input
                                className="form-control digits"
                                name="total_cod"
                                id="total_cod"
                                type="text"
                                value={formData.total_cod}
                                onChange={onChange}
                              />
                            </Col>
                            <Col sm="3">
                              <Label><b>Total HFL</b></Label>
                              <Input
                                className="form-control digits"
                                name="total_hfl"
                                id="total_hfl"
                                type="text"
                                value={formData.total_hfl}
                                onChange={onChange}
                              />
                            </Col>
                            <Col sm="3">
                              <Label><b>Total de livraison</b></Label>
                              <Input
                                className="form-control digits"
                                name="cous_livraison"
                                id="cous_livraison"
                                type="text"
                                value={formData.cous_livraison}
                                onChange={onChange}
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm="4">
                              <Label><b>Sélectionner le statut de la demande</b></Label>
                              <select 
                                className="form-control"
                                name="status"
                                id="status"
                                value={formData.status}
                                onChange={onChange}
                                >
                                    <option>En attente</option>
                                    <option value="pending">Pending</option>
                                    <option value="paid">Paid</option>
                              </select>
                            </Col>
                            <Col sm="4">
                                <Label><b>Date de paiement</b></Label>
                                <DatePicker
                                    className="form-control"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    filterDate={filterDate}
                                />
                            </Col>

                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ) : (
                ""
              )}
            </Col>

            <Row>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Col>
                <FormGroup className="mb-0">
                  <button
                    type="submit"
                    className="btn btn-success mr-3"
                    disabled={loading}
                  >
                    {loading ? "Submit..." : "Submit"}
                  </button>
                  <Link to={`/request-finance`}>
                    <Button color="danger">Annuler</Button>
                  </Link>
                  <br />
                  <br />
                </FormGroup>
              </Col>
            </Row>
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default EditFinanceRequest;
