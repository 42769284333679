import React, {Fragment, useState, useEffect, useCallback, useRef} from "react";
import { 
    Card, 
    Row,
    Col,
    Input, 
    Button, 
    Form,
    CardBody, 
    CardHeader, 
    Modal, 
    ModalBody, 
    FormGroup 
} from 'reactstrap'
import lel from "./images/book-a-demo.png";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {addNewDemande} from "../demandes/action";
import Footer from "./components/footer";
import Nava from "./components/Navbar"

class Firework {
    constructor(ctx, x, y) {
      this.x = x
      this.y = y
      const angle = Math.random() * Math.PI * 2
      const speed = Math.random() * 3 + 1
      this.dx = Math.cos(angle) * speed
      this.dy = Math.sin(angle) * speed
      this.radius = Math.random() * 2 + 1
      this.initialRadius = this.radius
      this.color = `hsl(${Math.random() * 360}, 50%, 50%)`
      this.ctx = ctx
      this.lifetime = 100
    }
  
    draw() {
      this.ctx.beginPath()
      this.ctx.arc(this.x, this.y, Math.max(this.radius, 0), 0, Math.PI * 2)
      this.ctx.fillStyle = this.color
      this.ctx.fill()
    }
  
    update() {
      this.x += this.dx
      this.y += this.dy
      this.dy += 0.05 // gravity
      this.radius = Math.max(this.initialRadius * (this.lifetime / 100), 0)
      this.lifetime -= 1
      this.draw()
    }
  
    isDead() {
      return this.lifetime <= 0
    }
  }

const Landing = ({addNewDemande}) => {
    const [, setsearchValue] = useState("");
    
    const [showConfetti, setShowConfetti] = useState(false)
    const [showFireworks, setShowFireworks] = useState(false)
    const canvasRef = useRef(null)
    const fireworksRef = useRef([])
    const animationRef = useRef(null)
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState({
        commande: 0, first_name: "", last_name: "", type: "Demo", demo: "", number: "", email: "", entreprise: "",
    });


    const onSubmit = (e) => {
        e.preventDefault();
        addNewDemande(formData);
    };

    const onChange = (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    useEffect(() => {
        const timer = setTimeout(() => setShowConfetti(true), 1000)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (showFireworks && canvasRef.current) {
          const canvas = canvasRef.current
          const ctx = canvas.getContext('2d')
          if (!ctx) return
    
          const resizeCanvas = () => {
            canvas.width = window.innerWidth
            canvas.height = window.innerHeight
          }
    
          resizeCanvas()
          window.addEventListener('resize', resizeCanvas)
    
          const animate = () => {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
    
            fireworksRef.current = fireworksRef.current.filter(firework => !firework.isDead())
    
            fireworksRef.current.forEach(firework => {
              firework.update()
            })
    
            if (Math.random() < 0.05) {
              const x = Math.random() * canvas.width
              const y = Math.random() * canvas.height
              for (let i = 0; i < 50; i++) {
                fireworksRef.current.push(new Firework(ctx, x, y))
              }
            }
    
            animationRef.current = requestAnimationFrame(animate)
          }
    
          animate()
    
          return () => {
            window.removeEventListener('resize', resizeCanvas)
            cancelAnimationFrame(animationRef.current)
            fireworksRef.current = []
          }
        }
      }, [showFireworks])

    const toggleFireworks = () => {
        setShowFireworks(prev => !prev)
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setsearchValue("");
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const toggle = () => setModal(!modal);
    return (
    <Fragment>
        <div style={{backgroundColor: "#fff", fontFamily: "Raleway"}}>

            <Nava addNewDemande={addNewDemande}/>
            <hr />
            <div className="birthday-container">
            <div className="birthday-logo">
                <span className="logo-text">Beezit</span>
                <span className="logo-number">3 Years</span>
            </div>

            <div className="offer-banner">
                <span>3 PARCELS OFF TODAY!</span>
            </div>

            <Card className="main-card">
                <CardHeader>
                <h3 className="card-title">Happy 3rd Birthday, Beezit! 🎉</h3>
                </CardHeader>
                <CardBody>
                <p className="celebration-text">Nous célébrons trois années de doux succès !</p>
                <p>Rejoignez notre célébration avec des offres exclusives et des surprises !</p>
                <div className="button-container">
                    <Button color="warning" className="claim-offer-btn" onClick={toggle}>
                        Claim Offer
                    </Button>
                    <Button outline color="warning" className="party-btn" onClick={toggleFireworks}>
                    {showFireworks ? "Stop" : "Start"} the Party!
                    </Button>
                </div>
                </CardBody>
            </Card>

            <div className="achievements-container">
                {[
                { title: "Happy Customers", value: "10,000+" },
                { title: "Parcels Delivered", value: "150,000+" },
                { title: "5-Star Reviews", value: "5,000+" }
                ].map((achievement, index) => (
                <Card key={index} className="achievement-card">
                    <CardBody>
                    <h3 className="achievement-title">{achievement.title}</h3>
                    <p className="achievement-value">{achievement.value}</p>
                    </CardBody>
                </Card>
                ))}
            </div>

            {showConfetti && (
                <div className="confetti-container">
                {[...Array(100)].map((_, i) => (
                    <div 
                    key={i} 
                    className="confetti-piece"
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        backgroundColor: ['#FFD700', '#FF6347', '#00CED1', '#FF69B4', '#32CD32'][Math.floor(Math.random() * 5)],
                        width: `${Math.random() * 10 + 5}px`,
                        height: `${Math.random() * 10 + 5}px`,
                      }}
                    />
                ))}
                </div>
            )}

            {showFireworks && (
                <canvas
                ref={canvasRef}
                className="fireworks-canvas"
                />
            )}

            {/* <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>BeezIT Birthday Offer</ModalHeader>
                <ModalBody>
                    <p>Rejoignez-nous pour célébrer notre 3e anniversaire avec notre offre d'anniversaire exclusive !</p><br/>
                    <b>Partagez la photo Beezit et taguez-la sur Instagram pour gagner 3 colis gratuits.</b><br/>
                    <b>Valable jusqu'au {new Date('10/15/2024').toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</b><br/><br/>
                    <p>Veuillez contacter notre équipe d'assistance au <a href="mailto:support@beezit.com"><b>support@beezit.com</b></a> Si vous avez besoin d'aide supplémentaire.</p><br/>
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
                </ModalFooter>
            </Modal> */}

<Modal
                centered
                size="xl"

                className="relative flex flex-col sm:justify-center items-center"
                isOpen={modal}
                toggle={() => setModal(!modal)}

            >
                <ModalBody style={{backgroundColor: "#F5F8FA", backgroundImage: `url("${lel}")`}}>
                    <Row>
                        <Col sm="5" style={{backgroundImage: `url("${lel}")`, backgroundSize: "cover", color: "white"}}>
                            <p className="m-2" style={{fontSize: "30px"}}>
                                Réserver une démo
                            </p>
                            <br/>
                            <p className="m-2" style={{fontSize: "15px"}}>
                                Beezit est la solution logistique tunisienne garantissant la
                                meilleure experience clients et augmentant vos ventes
                            </p>
                            <br/>
                            <li className="m-2">Accédez à une tarification simple</li>

                            <li className="m-2">
                                Accédez à une solution de livraison premier ordre
                            </li>
                            <li className="m-2"> Augmentez vos ventes</li>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody>

                                    <Form onSubmit={(e) => onSubmit(e)}>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="last_name"
                                                        placeholder="Nom*"
                                                        value={formData.last_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="text"
                                                        name="first_name"
                                                        placeholder="Prénom*"
                                                        value={formData.first_name}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="number"
                                                        name="number"
                                                        placeholder="Numéro de téléphone*"
                                                        value={formData.number}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                {" "}
                                                <FormGroup>
                                                    <Input
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                        type="emails"
                                                        name="email"
                                                        placeholder="Email*"
                                                        value={formData.email}
                                                        onChange={(e) => onChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <select
                                                style={{height: "50px", borderRadius: "10px"}}
                                                className="form-control digits "
                                                name="type"
                                                value={formData.type}
                                                onChange={(e) => onChange(e)}
                                            >
                                                <option value="" disabled selected hidden>
                                                    Vous vendez principalemens vos produits sur*
                                                </option>
                                                <option hidden> vous vendez sur ?</option>
                                                <option value={"website"}>Site web</option>
                                                <option value={"social_media"}>
                                                    Résaux socieaux{" "}
                                                </option>
                                            </select>
                                        </FormGroup>
                                        <p>
                                            Beezit a besoin des cordonnées que vous nous fournisser
                                            pour vous contacter au sujet de nos produits et
                                            services. Vous pouvez vous désabonner de ces
                                            communications à tout moment. Pour plus d'informations,
                                            veuillez consulter notre politique de confidentalité
                                        </p>
                                        <Row>
                                            <Col>
                                                <button
                                                    type="submit"
                                                    onClick={() => setModal(!modal)}
                                                    className="p-2 text-white ml-1 btn"
                                                    style={{
                                                        backgroundColor: "#193256",
                                                        width: "100%",
                                                        borderRadius: "20px",
                                                    }}
                                                >
                                                    Soumettre
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="1"></Col>
                    </Row>
                </ModalBody>
            </Modal>

            <style jsx>{`
                .birthday-container {
                    min-height: 100vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 1rem;
                    position: relative;
                    overflow: hidden;
                    background: linear-gradient(to right, #fbbf24, #f59e0b, #d97706);
                }
                .birthday-logo {
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                    display: flex;
                    align-items: center;
                    background: white;
                    border-radius: 9999px;
                    padding: 0.5rem;
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                }
                .logo-text {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #1f2937;
                    margin-right: 0.5rem;
                }
                .logo-number {
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: #f59e0b;
                }
                .offer-banner {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: #fde68a;
                    color: #1f2937;
                    padding: 0.5rem 1rem;
                    transform: rotate(45deg) translate(30%, -10%);
                    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                    font-weight: 600;
                }
                .main-card {
                    width: 100%;
                    max-width: 24rem;
                    margin-bottom: 2rem;
                    z-index: 10;
                }
                .card-title {
                    font-size: 1.875rem;
                    font-weight: bold;
                    text-align: center;
                    margin: 0;
                }
                .celebration-text {
                    margin-bottom: 1rem;
                    font-size: 1.125rem;
                }
                .button-container {
                    display: flex;
                    justify-content: center;
                    gap: 1rem;
                    margin-top: 1.5rem;
                }
                .claim-offer-btn, .party-btn {
                    font-weight: 600;
                }
                .achievements-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    gap: 1rem;
                    width: 100%;
                    max-width: 48rem;
                    z-index: 10;
                }
                .achievement-card {
                    text-align: center;
                }
                .achievement-title {
                    font-size: 1.125rem;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                }
                .achievement-value {
                    font-size: 1.875rem;
                    font-weight: bold;
                    color: #f59e0b;
                }
                .confetti-container {
                    position: absolute;
                    inset: 0;
                    pointer-events: none;
                }
                .confetti-piece {
                    position: absolute;
                    border-radius: 50%;
                    animation: fall 3s linear infinite;
                }
                .fireworks-canvas {
                    position: absolute;
                    inset: 0;
                    pointer-events: none;
                }
                @keyframes fall {
                    0% { transform: translateY(-100vh) rotate(0deg); }
                    100% { transform: translateY(100vh) rotate(360deg); }
                }
            `}</style>
            </div>
            <hr />
            <Footer/>
        </div>
    </Fragment>);
};
Landing.propTypes = {
    addNewDemande: PropTypes.func.isRequired,
};

export default connect(null, {addNewDemande})(Landing);