export const settings = {
  isAuthenticated: false,
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  group: "",
  user_id: "",
  avatar: "",
  access: "",
  refresh: "",
  accept_terms: "",
  fournisseur: "",
  stock_service: "",
  username: "",
  payment_day: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { settings };