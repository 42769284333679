export const ADD_TO_CART = "ADD_TO_CART";
export const GET_COLI = "GET_COLI";
export const GET_TOTAL_FINANCE = "GET_TOTAL_FINANCE";
export const TOTAL_FINANCE_LOADING = "TOTAL_FINANCE_LOADING";
export const TOTAL_FINANCE_ERROR = "TOTAL_FINANCE_ERROR";
export const RESET_COLI = "RESET_COLI";
export const DELETE_COLIS = "DELETE_COLIS";
export const DELETE_TRACKING = "DELETE_TRACKING";
export const GET_COLISP = "GET_COLISP";
export const GET_COLISP1 = "GET_COLISP1";
export const COLISP_LOADING = "COLISP_LOADING";
export const COLISP_ERROR = "COLISP_ERROR";
export const GET_COLISH = "GET_COLISH";
export const COLISH_LOADING = "COLISH_LOADING";
export const COLISH_ERROR = "COLISH_ERROR";
export const GET_COLISM = "GET_COLISM";
export const COLISM_LOADING = "COLISM_LOADING";
export const COLISM_ERROR = "COLISM_ERROR";
export const GET_CHART = "GET_CHART";
export const CHART_LOADING = "CHART_LOADING";
export const CHART_ERROR = "CHART_ERROR";
export const GET_HISTORY = "GET_HISTORY";
export const HISTORY_LOADING = "HISTORY_LOADING";
export const HISTORY_ERROR = "HISTORY_ERROR";
export const COLIS_LOADING = "COLIS_LOADING";
export const GET_COLIS = "GET_COLIS";
export const COLIS_ERROR = "COLIS_ERROR";
export const GENERATED_LOADING = "GENERATED_LOADING";
export const GET_GENERATE= "GET_GENERATE";
export const GET_GENERATED= "GET_GENERATED";
export const GENERATE_ERROR = "GENERATE_ERROR";
export const GENERATED_ERROR = "GENERATED_ERROR";
export const CLEAR_COLIS = "CLEAR_COLIS";
export const GET_LIVREUR = "GET_LIVREUR";
export const LIVREUR_LOADING = "LIVREUR_LOADING";
export const LIVREUR_ERROR = "LIVREUR_ERROR";
export const GET_ADRESSE = "GET_ADRESSE";
export const ADRESSE_LOADING = "ADRESSE_LOADING";
export const ADRESSE_ERROR = "ADRESSE_ERROR";
export const UPDATE_COLIS_REQUEST = "UPDATE_COLIS_REQUEST";
export const UPDATE_COLIS_SUCCESS = "UPDATE_COLIS_SUCCESS";
export const UPDATE_COLIS = "UPDATE_COLIS";
export const GESTION_LOADING = "GESTION_LOADING";
export const GET_GESTIONS = "GET_GESTIONS";
export const GESTION_ERROR = "UPDATE_COLIS";
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const DASHBOARD_ERROR = "DASHBOARD_ERROR";
export const VILLEVENTE_LOADING = "VILLEVENTE_LOADING";
export const GET_VILLEVENTE = "GET_VILLEVENTE";
export const VILLEVENTE_ERROR = "VILLEVENTE_ERROR";
export const SECTEURPERCENTS_LOADING = "SECTEURPERCENTS_LOADING";
export const GET_SECTEURPERCENTS = "GET_SECTEURPERCENTS";
export const SECTEURPERCENTS_ERROR = "SECTEURPERCENTS_ERROR";
export const GET_COLISS = "GET_COLISS";
export const COLISS_LOADING = "COLISS_LOADING";
export const COLISS_ERROR = "COLISS_ERROR";
export const GET_COLISR = "GET_COLISR";
export const COLISR_LOADING = "COLISR_LOADING";
export const COLISR_ERROR = "COLISR_ERROR";
export const GET_COLISE = "GET_COLISE";
export const COLISE_LOADING = "COLISE_LOADING";
export const COLISE_ERROR = "COLISE_ERROR";
export const GET_COLISMO = "GET_COLISMO";
export const COLISMO_LOADING = "COLISMO_LOADING";
export const COLISMO_ERROR = "COLISMO_ERROR";
export const GET_COLISPY = "GET_COLISPY";
export const COLISPY_LOADING = "COLISPY_LOADING";
export const COLISPY_ERROR = "COLISPY_ERROR";
export const GET_FOURNISSEUR = "GET_FOURNISSEUR";
export const GET_FOURNISSEURS = "GET_FOURNISSEURS";
export const FOURNISSEUR_LOADING = "FOURNISSEUR_LOADING";
export const FOURNISSEUR_ERROR = "FOURNISSEUR_ERROR";
export const GET_FOURNISSEURSP = "GET_FOURNISSEURSP";
export const FOURNISSEURP_LOADING = "FOURNISSEURP_LOADING";
export const FOURNISSEURP_ERROR = "FOURNISSEURP_ERROR";
export const UPDATE_FOURNISSEUR = "UPDATE_FOURNISSEUR";
export const GET_FINANCE = "GET_FINANCE";
export const GET_FINANCES = "GET_FINANCES";
export const FINANCE_LOADING = "FINANCE_LOADING";
export const FINANCE_ERROR = "FINANCE_ERROR";
export const GET_FINANCESP = "GET_FINANCESP";
export const FINANCEP_LOADING = "FINANCEP_LOADING";
export const FINANCEP_ERROR = "FINANCEP_ERROR";
export const UPDATE_FINANCE = "UPDATE_FINANCE";
export const GET_SECTEUR = "GET_SECTEUR";
export const GET_SECTEURS = "GET_SECTEURS";
export const SECTEUR_LOADING = "SECTEUR_LOADING";
export const SECTEUR_ERROR = "SECTEUR_ERROR";
export const UPDATE_SECTEUR = "UPDATE_SECTEUR";
export const GET_COMPTE = "GET_COMPTE";
export const GET_COMPTES = "GET_COMPTES";
export const GET_COMMERCIALS = "GET_COMMERCIALS";
export const COMMERCIALS_LOADING = "COMMERCIALS_LOADING";
export const COMMERCIALS_ERROR = "COMMERCIALS_ERROR";
export const COMPTE_LOADING = "COMPTE_LOADING";
export const COMPTE_ERROR = "COMPTE_ERROR";
export const UPDATE_COMPTE = "UPDATE_COMPTE";
export const GET_RECLAMATION = "GET_RECLAMATION";
export const GET_RECLAMATIONS = "GET_RECLAMATIONS";
export const RECLAMATION_LOADING = "RECLAMATION_LOADING";
export const RECLAMATION_ERROR = "RECLAMATION_ERROR";
export const UPDATE_RECLAMATION = "UPDATE_RECLAMATION";
export const GET_REPONSE = "GET_REPONSE";
export const GET_REPONSES = "GET_REPONSES";
export const REPONSE_LOADING = "REPONSE_LOADING";
export const REPONSE_ERROR = "REPONSE_ERROR";
export const UPDATE_REPONSE = "UPDATE_REPONSE";
export const GET_GROUP = "GET_GROUP";
export const GET_GROUPS = "GET_GROUPS";
export const GROUP_LOADING = "GROUP_LOADING";
export const GROUP_ERROR = "GROUP_ERROR";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const PERMISSION_LOADING = "PERMISSION_LOADING";
export const PERMISSION_ERROR = "PERMISSION_ERROR";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_VILLE = "GET_VILLE";
export const VILLE_LOADING = "VILLE_LOADING";
export const VILLE_ERROR = "VILLE_ERROR";
export const GET_DELIGATION = "GET_DELIGATION";
export const DELIGATION_LOADING = "DELIGATION_LOADING";
export const DELIGATION_ERROR = "DELIGATION_ERROR";
export const GET_CITE = "GET_CITE";
export const CITE_LOADING = "CITE_LOADING";
export const CITE_ERROR = "CITE_ERROR";
export const GET_VILLEF = "GET_VILLEF";
export const VILLEF_LOADING = "VILLEF_LOADING";
export const VILLEF_ERROR = "VILLEF_ERROR";
export const GET_DELIGATIONF = "GET_DELIGATIONF";
export const DELIGATIONF_LOADING = "DELIGATIONF_LOADING";
export const DELIGATIONF_ERROR = "DELIGATIONF_ERROR";
export const GET_CITEF = "GET_CITEF";
export const CITEF_LOADING = "CITEF_LOADING";
export const CITEF_ERROR = "CITEF_ERROR";
export const GET_FILIERE = "GET_FILIERE";
export const FILIERE_LOADING = "FILIERE_LOADING";
export const FILIERE_ERROR = "FILIERE_ERROR";
export const GET_TRACKING = "GET_TRACKING";
export const UPDATE_TRACKINGS = "UPDATE_TRACKINGS";
export const TRACKING_ERROR = "TRACKING_ERROR";
export const TRACKING_LOADING = "TRACKING_LOADING";
export const GET_TRACKING_ACTIVITIES = "GET_TRACKING_ACTIVITIES";
export const TRACKING_ACTIVITIES_ERROR = "TRACKING_ACTIVITIES_ERROR";
export const TRACKING_ACTIVITIES_LOADING = "TRACKING_ACTIVITIES_LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERTS = "SET_ALERT";
export const REMOVE_ALERTS = "REMOVE_ALERT";
export const GET_STOCK = "GET_STOCK";
export const GET_STOCKS = "GET_STOCKS";
export const GET_STOCK_ACTIVITIES = "GET_STOCK_ACTIVITIES";
export const STOCK_ACTIVITIES_LOADING = "STOCK_ACTIVITIES_LOADING";
export const STOCK_LOADING = "STOCK_LOADING";
export const STOCK_ERROR = "STOCK_ERROR";
export const STOCK_ACTIVITIES_ERROR = "STOCK_ACTIVITIES_ERROR";
export const GET_CATEGORIE = "GET_CATEGORIE";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORIE_LOADING = "CATEGORIE_LOADING";
export const CATEGORIE_ERROR = "CATEGORIE_ERROR";
export const GET_SOUS_CATEGORIE = "GET_SOUS_CATEGORIE";
export const GET_SOUS_CATEGORIES = "GET_SOUS_CATEGORIES";
export const SOUS_CATEGORIE_LOADING = "SOUS_CATEGORIE_LOADING";
export const SOUS_CATEGORIE_ERROR = "SOUS_CATEGORIE_ERROR";
export const GET_PRODUIT = "GET_PRODUIT";
export const GET_PRODUITS = "GET_PRODUITS";
export const PRODUIT_LOADING = "PRODUIT_LOADING";
export const PRODUIT_ERROR = "PRODUIT_ERROR";
export const GET_RIGHT_BAR_TRACKING_ACTIVITIES = "GET_RIGHT_BAR_TRACKING_ACTIVITIES";
export const RIGHT_BAR_TRACKING_ACTIVITIES_LOADING = "RIGHT_BAR_TRACKING_ACTIVITIES_LOADING";
export const RIGHT_BAR_TRACKING_ACTIVITIES_ERROR = "RIGHT_BAR_TRACKING_ACTIVITIES_ERROR";
export const GET_RIGHT_BAR_TRACKING = "GET_RIGHT_BAR_TRACKING";
export const RIGHT_BAR_TRACKING_LOADING = "RIGHT_BAR_TRACKING_LOADING";
export const RIGHT_BAR_TRACKING_ERROR = "RIGHT_BAR_TRACKING_ERROR";
export const GET_RIGHT_BAR_RECLAMATION = "GET_RIGHT_BAR_RECLAMATION";
export const RIGHT_BAR_RECLAMATION_LOADING = "RIGHT_BAR_RECLAMATION_LOADING";
export const RIGHT_BAR_RECLAMATION_ERROR = "RIGHT_BAR_RECLAMATION_ERROR";
export const RIGHT_BAR_COMPTE_ERROR = "RIGHT_BAR_COMPTE_ERROR";
export const RIGHT_BAR_COMPTE_LOADING = "RIGHT_BAR_COMPTE_LOADING";
export const GET_RIGHT_BAR_COMPTE = "GET_RIGHT_BAR_COMPTE";
export const GET_DEMANDES = "GET_DEMANDES";
export const GET_DEMANDE = "GET_DEMANDE";
export const UPDATE_DEMANDE = "UPDATE_DEMANDE";
export const DEMANDE_LOADING = "DEMANDE_LOADING";
export const DEMANDE_ERROR = "DEMANDE_ERROR";
export const GET_ADRESSES = "GET_ADRESSES";
export const UPDATE_ADRESSE = "UPDATE_ADRESSE";
export const EXPORT_LOADING = "EXPORT_LOADING";
export const EXPORT_ERROR = "EXPORT_ERROR";
export const GET_EXPORT = "GET_EXPORT";
export const GET_TOTAL = "GET_TOTAL";
export const TOTAL_LOADING = "TOTAL_LOADING";
export const TOTAL_ERROR = "TOTAL_ERROR";
export const GENERATE_ORDER = "GENERATE_ORDER";
export const GENERATE_ORDER_SUCCESS ="GENERATE_ORDER_SUCCESS";
export const GENERATE_ORDER_FAIL = "GENERATE_ORDER_FAIL"
export const UNSEEN_NEWS = "UNSEEN_NEWS"
export const UNSEEN_NEWS_LOADING = "UNSEEN_NEWS_LOADING"
export const UNSEEN_NEWS_ERROR = "UNSEEN_NEWS_ERROR"
export const NEWS_UPDATE = "NEWS_UPDATE"
export const GET_NEWS = "GET_NEWS"
export const GET_NEW = "GET_NEW"
export const NEWS_ERROR = "NEWS_ERROR"
export const NEWS_LOADING = "NEWS_LOADING"
export const GET_LOGS = "GET_LOGS"
export const LOGS_ERROR = "LOGS_ERROR"
export const LOGS_LOADING = "LOGS_LOADING"
export const GET_FINANCE_REQUEST = "GET_FINANCE_REQUEST"
export const GET_FINANCE_REQUESTS = "GET_FINANCE_REQUESTS"
export const GET_TOTAL_FINANCE_REQUEST = "GET_TOTAL_FINANCE_REQUEST"
export const TOTAL_FINANCE_REQUEST_LOADING = "TOTAL_FINANCE_REQUEST_LOADING"
export const TOTAL_FINANCE_REQUEST_ERROR = "TOTAL_FINANCE_REQUEST_ERROR"
export const FINANCE_REQUEST_LOADING = "FINANCE_REQUEST_LOADING"
export const FINANCE_REQUEST_ERROR = "FINANCE_REQUEST_ERROR"
export const GET_FINANCE_REQUESTSP = "GET_FINANCE_REQUESTSP"
export const FINANCE_REQUESTP_LOADING = "FINANCE_REQUESTP_LOADING"
export const FINANCE_REQUESTP_ERROR = "FINANCE_REQUESTP_ERROR"